
import img1 from '../images/layouts/w1.jpeg'
import img2 from '../images/layouts/w2.jpeg'
import img3 from '../images/layouts/w3.jpeg'

const dataGallery = [

    {
        id: 1,
        img: img1,
        name: 'Warehouse',
        position: ''
    },
    {
        id: 2,
        img: img2,
        name: 'warehouse',
        position: ''
    },

    {
        id: 3,
        img: img3,
        name: 'warehouseme',
        position: ''
    },
    

]

export default dataGallery;