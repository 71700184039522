import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

Project3.propTypes = {
  data: PropTypes.array,
};

function Project3(props) {
  const { data } = props;
  const [filteredData, setFilteredData] = useState([]);
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState("All Products");

  useEffect(() => {
    if (data && data.length > 0) {
      // Extract unique collections
      const uniqueCollections = [...new Set(data.map(item => item.collection))];
      setCollections(["All Products", ...uniqueCollections]);
      setFilteredData(data);
    }
  }, [data]);

  const handleCollectionChange = (collection) => {
    setSelectedCollection(collection);
    if (collection === "All Products") {
      setFilteredData(data);
    } else {
      setFilteredData(data.filter(item => item.collection === collection));
    }
  };

  if (!data || data.length === 0) {
    return <div>No products available</div>;
  }

  return (
    <section className="nft">
      <div className="container">
        <div className="row mb-4">
          <div className="col-12">
            <select
              value={selectedCollection} 
              onChange={(e) => handleCollectionChange(e.target.value)}
              className="form-select"
              style={{ backgroundColor: 'transparent', color: 'white' }}
            >
              {collections.map((collection) => (
                <option key={collection} value={collection}>{collection}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          {filteredData.map((item) => (
            <div key={item.id} className="col-xl-3 col-md-6">
              <div className="nft-item">
                <div className="card-media">
                  <Link to="#">
                    <img
                      src={item.img}
                      alt={`Product ${item.id}`} 
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Link>
                </div>
                <div className="card-title">
                  <Link to="#" className="h5">
                    {item.title}
                  </Link>
                </div>
                <div className="meta-info">
                  <div className="author">
                    <div className="info">
                      <p>{item.modelNumber}</p>
                    </div>
                  </div>
                </div>
                <div className="card-bottom style-explode">
                  <div className="price">
                    <div className="price-details">
                      {/* Price can be added here if needed */}
                    </div>
                  </div>
                  <div className="button-place-bid">
                    <Link to={`/contact/${item.id}`} className="sc-button">
                      <span>Contact Now</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Project3;