import About from "../components/about/About";
import Contact from "./Contact";
import Nfts from "./Nfts";
import Main from './Main';
import Gallery from "../components/Gallery/Gallery";
// import Bg from "../components/animations/bg";





const routes = [
  // {path: '' ,component: <Bg />},
  { path: '/', component: <Main />},
  { path: '/Collection', component: <Nfts />},
  { path: '/gallery', component: <Gallery />},
  // { path: '/branches', component: <Branches />},
  { path: '/about', component: <About />},
  { path: '/contact', component: <Contact />},



]

export default routes;