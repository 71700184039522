import React , {useState} from 'react';



function Speciality(props) {

    const {data} = props;

    const [dataBlock] = useState(
        {
            subheading: 'Why Us?',
            heading: 'Premium Solutions for Your LED Needs',
            desc: 'Offering top-quality LED products with fifteen years of trusted expertise.' ,
            
        }
    )
    return (
        <section className="speciality">
                <div className="shape right"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h6 
                                style={{
                                    fontSize:'1.5rem'
                                }}
                                className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                <h3 className="heading pd">{dataBlock.heading}</h3>
                                    <p 
                                    style={{
                                        fontSize:'1.2rem'
                                    }}
                                    className="">{dataBlock.desc}</p>
                            </div>
                        </div>
                        {
                            data.map(idx => (
                                <div key={idx.id} className="col-xl-3 col-md-6">
                                    <div className="speciality-box" >
                                        <div className="icon">
                                            <img src={idx.img} alt="img" />
                                        </div>
                                        <h5 className="title">{idx.title}</h5>
                                        <p
                                        style={{
                                            fontSize:'1.2rem'
                                        }}
                                        >{idx.desc}</p>
                                        {/* <h3 >{idx.id}</h3> */}
                                    </div>
                                </div>
                            ))
                        }
                        
                    </div>
                </div>
            </section>

    );
}

export default Speciality;