import React, { useEffect, useRef, useState, useCallback } from 'react';

const Bg = () => {
  const canvasRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const ledsRef = useRef([]);

  const createLeds = useCallback((width, height) => {
    const ledCount = Math.min(50, Math.floor((width * height) / 20000)); // Reduced LED count
    return Array.from({ length: ledCount }, () => ({
      x: Math.random() * width,
      y: Math.random() * height,
      radius: 2 + Math.random(), 
      baseRadius: 2 + Math.random(),
      opacity: Math.random(),
      speed: 0.02 + Math.random() * 0.02 
    }));
  }, []);

  const drawLEDs = useCallback((ctx, leds, width, height) => {
    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, width, height);

    leds.forEach(led => {
      const gradient = ctx.createRadialGradient(led.x, led.y, 0, led.x, led.y, led.radius * 2);
      gradient.addColorStop(0, `rgba(255, 255, 255, ${led.opacity})`);
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

      ctx.beginPath();
      ctx.arc(led.x, led.y, led.radius * 2, 0, Math.PI * 2);
      ctx.fillStyle = gradient;
      ctx.fill();

      led.opacity += led.speed;
      if (led.opacity > 1 || led.opacity < 0) led.speed = -led.speed;
      led.radius = led.baseRadius + Math.sin(Date.now() * 0.002 + led.baseRadius) * 0.1;
    });
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    ledsRef.current = createLeds(dimensions.width, dimensions.height);

    const animate = () => {
      drawLEDs(ctx, ledsRef.current, dimensions.width, dimensions.height);
      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    const debouncedResize = debounce(() => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
      ledsRef.current = createLeds(window.innerWidth, window.innerHeight);
    }, 250);

    window.addEventListener('resize', debouncedResize);

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('resize', debouncedResize);
    };
  }, [dimensions, createLeds, drawLEDs]);

  return (
    <canvas ref={canvasRef} width={dimensions.width} height={dimensions.height} style={{ display: 'block', position: 'fixed', top: 0, left: 0, zIndex: -1 }} />
  );
};

// Debounce function to limit the frequency of resize events
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default Bg;