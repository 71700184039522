import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import menus from "../../pages/menu";
import logo from "../../assets/images/logo/logo.png";
import "./styles.scss";

const Header = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 300);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [menuActive, setMenuActive] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
    setActiveIndex(null);
  };

  const handleDropdown = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle submenu on click
  };

  // Close submenu on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".menu-item")) {
        setActiveIndex(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Recursive function to render menu items
  const renderMenuItems = (items, parentIndex = null) => {
    return items.map((data, idx) => {
      const currentIndex =
        parentIndex !== null ? `${parentIndex}-${idx}` : `${idx}`;

      return (
        <li
          key={currentIndex}
          onClick={() => handleDropdown(currentIndex)}
          className={`menu-item ${
            data.namesub ? "menu-item-has-children" : ""
          } ${activeIndex === currentIndex ? "active" : ""}`}
          style={{
            color: activeIndex === currentIndex ? "grey" : "white",
            backgroundColor:
              activeIndex === currentIndex ? "black" : "transparent",
          }}
        >
          <Link
            to={data.links}
            style={{
              color: activeIndex === currentIndex ? "grey" : "white",
            }}
          >
            {data.name || data.sub}
          </Link>
          {data.namesub && (
            <ul className="sub-menu">
              {renderMenuItems(data.namesub, currentIndex)}
            </ul>
          )}
        </li>
      );
    });
  };

  return (
    <header
      style={{
        backgroundColor: "black",
      }}
      id="header_main"
      className={`header ${scroll ? "is-fixed" : ""}`}
    >
      <div className="container big">
        <div className="row">
          <div className="col-12">
            <div className="header__body">
              <div className="header__logo">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Link to="/">
                    <img
                      style={{
                        height: "auto",
                        width: "200px",
                      }}
                      id="site-logo"
                      src={logo}
                      alt="Divubrite"
                    />
                  </Link>
                  <p
                    style={{
                      marginTop: "15%",
                      fontSize: "25px",
                      marginLeft: "-10px",
                      fontWeight: "bold",
                      background:
                        "linear-gradient(264.28deg, #FFFFFF -38.2%, #000000 103.12%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  ></p>
                </div>
              </div>

              <div className="header__right">
                <nav
                  style={{ color: "white" }}
                  id="main-nav"
                  className={`main-nav ${menuActive ? "active" : ""}`}
                >
                  <ul id="menu-primary-menu" className="menu">
                    {renderMenuItems(menus)}
                  </ul>
                </nav>
                <div
                  style={{ color: "white" }}
                  className={`mobile-button ${menuActive ? "active" : ""}`}
                  onClick={handleMenuActive}
                >
                  <span></span>
                </div>
              </div>

              <div className="header__action">
                <Link to="/contact" className="action-btn">
                  <span>Contact</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
