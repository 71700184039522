import React from 'react';
import PropTypes from 'prop-types';

import line from '../../assets/images/background/line-2.png';

Team2.propTypes = {
    data: PropTypes.array.isRequired,
    subheading: PropTypes.string
};

function Team2(props) {
    const { data, subheading } = props;

    // Sort entire data array based on id in ascending order
    const sortedData = data.sort((a, b) => a.id - b.id);

    // Slice the first 7 items from sorted data
    const displayedData = sortedData.slice(0, 7);

    return (
        <section className="team s2">
            <div className="shape right"></div>
            <img src={line} alt="" className="img-line" />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>{subheading}</span></h6>
                        </div>
                    </div>
                    {displayedData.map(idx => (
                        <div key={idx.id} className="col-xl-3 col-md-6">
                            <div className="team-box">
                                <div    style={{height:'50vh', width:'auto',}} className="image">
                                    {/* <Link to="/team"> */}
                                    <img 
                                    style={{ maxWidth: "100%",
                                        maxHeight: "100%",}}
                                    src={idx.img} alt={idx.name} />
                                    {/* </Link> */}
                                    {/* <ul className="list-social">
                                        <li><Link to="#">
                                            <span className="icon-facebook"></span>
                                        </Link></li>
                                        <li><Link to="#">
                                            <span className="icon-twitter"></span>
                                        </Link></li>
                                        <li><Link to="#">
                                            <span className="icon-plan"></span>
                                        </Link></li>
                                    </ul> */}
                                </div>
                                <div className="content">
                                    {/* <Link to="/team" className="h5 name">{idx.name}</Link> */}
                                    <p className="position">{idx.position}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Team2;