import { db } from '../../config/config';
import { collection, getDocs } from "firebase/firestore";

const fetchCollectionsData = async () => {
  const collectionsData = [];

  try {
    const collectionsRef = collection(db, "products", "lights", "collections");
    const collectionsSnapshot = await getDocs(collectionsRef);

    for (const collectionDoc of collectionsSnapshot.docs) {
      const collectionName = collectionDoc.id;
      const productsRef = collection(collectionsRef, collectionDoc.id, "products");
      const productsSnapshot = await getDocs(productsRef);

      productsSnapshot.forEach(doc => {
        collectionsData.push({
          id: doc.id,
          title: doc.data().Title,
          img: doc.data().img,
          modelNumber: doc.data().modelNumber,
          collection: collectionName  
        });
      });
    }
  } catch (error) {
    console.error("Error fetching collections data:", error);
  }

  return collectionsData;
};

const dataItem = await fetchCollectionsData();
export default dataItem;