import React, { useState, useEffect } from "react";
import vid1 from "../../assets/Banner/0.mov";
import Button from "../button/Button";

function About(props) {
  const [isVideo, setIsVideo] = useState(true);
  const mediaPath = vid1;

  useEffect(() => {
    const video = document.createElement("video");
    video.src = mediaPath;
    video.onloadedmetadata = () => setIsVideo(true);
    video.onerror = () => setIsVideo(false);
    return () => {
      video.remove();
    };
  }, [mediaPath]);

  const [dataImg] = useState([
    {
      id: 1,
      img: vid1,
      class: "img1",
    }
  ]);

  const [dataBlock] = useState({
    subheading: "About us",
    heading: "Our core values",
    desc1: "Customer satisfaction is our priority. For over 15 years, we've specialized in providing premium fancy lighting and commercial light solutions to medium and small-sized businesses across Kerala, Tamil Nadu, Telangana, and Karnataka. Our deep industry expertise ensures we deliver high-quality, reliable, and aesthetically pleasing lighting solutions tailored to your specific needs.",
    desc2: "At our core, we value quality and affordability, ensuring you receive top-notch lighting solutions at competitive prices. Explore our wide range including gate lights, wall lights, chandelier lights, panel lights, and COB lights, all designed to enhance the ambiance and functionality of your spaces. Experience the difference with our premium lighting solutions tailored just for you.",
  });

  return (
    <section className="about">
      <div className="shape"></div>
      <div className="container">
        <div className="row rev">
          <div className="col-xl-6 col-md-12">
            <div className="about__right">
              <div className="images ">
                {dataImg.map((idx) => (
                  <div key={idx.id} className={idx.class}>
                    {isVideo ? (
                      <video
                        src={idx.img}
                        autoPlay
                        loop
                        muted
                        playsInline
                        style={{
                          width: '100%',
                          height: '65vh',
                          objectFit: 'cover',
                          pointerEvents: 'none'
                        }}
                      />
                    ) : (
                      <img
                        style={{ borderRadius: "50%", width: '100%', height: '65vh', objectFit: 'cover' }}
                        src={idx.img}
                        alt="Divubrite"
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="block-text">
              <h6
                style={{
                  fontSize: "1.5rem",
                }}
                className="sub-heading"
              >
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading">{dataBlock.heading}</h3>
              <p style={{ fontSize: "1.1rem" }} className="mb-17">
                {dataBlock.desc1}
              </p>
              <p style={{ fontSize: "1.1rem" }} className="mb-26">
                {dataBlock.desc2}
              </p>
              <Button link="/contact" title="Contact Us" />
              <div
                style={{
                  marginTop: "5vh",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;