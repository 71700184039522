import React from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Title from "../../assets/images/logo/TITLE.png";
import BannerImg from "../../assets/Banner/banner_img.jpeg";

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const glow = keyframes`
  0% { box-shadow: 0 0 10px rgba(255, 255, 255, 0.7), 0 0 20px rgba(255, 255, 255, 0.5); }
  50% { box-shadow: 0 0 30px rgba(255, 255, 255, 0.9), 0 0 50px rgba(255, 255, 255, 0.7); }
  100% { box-shadow: 0 0 10px rgba(255, 255, 255, 0.7), 0 0 20px rgba(255, 255, 255, 0.5); }
`;

const particles = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`;

const ImageWrapper = styled.div`
  max-width: 80%;
  height: 80vh;
  animation: ${float} 6s ease-in-out infinite, ${glow} 3s ease-in-out infinite;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 40px rgba(255, 255, 255, 0.9);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.3s ease;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background: white;
    border-radius: 50%;
    animation: ${particles} 2s ease-in-out infinite;
  }

  &::before {
    top: 10%;
    left: 10%;
  }

  &::after {
    bottom: 10%;
    right: 10%;
  }
`;

function Banner(props) {
  return (
    <section 
      style={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden'
      }}
      className="banner">
      <div className="shape right"></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="banner__left">
              <div className="block-text">
                <h2
                  style={{
                    fontSize: "clamp(2rem, 5vw, 4rem)",
                    position: "relative",
                    animation: "Animation 5s linear infinite alternate-reverse both running",
                  }}
                  className="heading"
                >
                  <img src={Title} alt="Title" style={{maxWidth: '100%', height: 'auto'}} />
                </h2>
                <p
                  style={{
                    fontSize: "clamp(1rem, 3vw, 1.8rem)",
                  }}
                  className="desc"
                >
                  We Lead Others Follow
                </p>
                <Link to="/collection" className="action-btn">
                  <span>Collection</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="banner__right">
              <ImageWrapper>
                <img src={BannerImg} alt="Banner" />
              </ImageWrapper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;