import React, { useEffect, useRef } from 'react';
import AOS from 'aos';
import { useLocation, Route, Routes } from 'react-router-dom';
import routes from './pages';
import Page404 from './pages/404';
import Header from './components/header/Header';
import Bg from './components/animations/bg';
import './App.css';
import ReactGa from 'react-ga4';

const TRACKING_ID = 'G-WTCPP6NKX2';
ReactGa.initialize(TRACKING_ID);

function App() {
    const location = useLocation();
    const startTimeRef = useRef(Date.now()); // Using useRef for mutable startTime

    useEffect(() => {
        AOS.init({
            duration: 2000,
        });

        // Add styles to lock horizontal scroll
        const style = document.createElement('style');
        style.innerHTML = `
            html, body {
                overflow-x: hidden;
                width: 100%;
            }
        `;
        document.head.appendChild(style);

        // Track initial page view
        ReactGa.send({ hitType: 'pageview', page: location.pathname });

        // Clean up the style on component unmount
        return () => {
            document.head.removeChild(style);
        };
    }, [location.pathname]);

    useEffect(() => {
        // Track page views on route change
        ReactGa.send({ hitType: 'pageview', page: location.pathname });

        // Reset the start time on route change
        startTimeRef.current = Date.now(); // Update the useRef value

        return () => {
            const timeSpent = Date.now() - startTimeRef.current;
            ReactGa.send({
                hitType: 'timing_complete',
                name: 'load',
                value: timeSpent,
                eventCategory: 'Timing',
                eventAction: 'User Time Spent',
            });
        };
    }, [location.pathname]);

    return (
        <div className="app-container">
            <Bg />
            <Header />
            <main className="content">
                <Routes>
                    {routes.map((data, idx) => (
                        <Route key={idx} path={data.path} element={data.component} exact />
                    ))}
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </main>
        </div>
    );
}

export default App;